@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --primary-brand-color: #f39324;
  --primary-interactive-color: var(--primary-brand-color);
}

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html, body {
  height: 100vh;
  width: 100vw;

  max-height: 100vh;
  max-width: 100vw;

  background-color: initial!important;
  color: initial!important;
}

body {
  margin: 0;
}